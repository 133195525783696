"use client";
import { useEffect } from "react";
import { useRouter } from "next/navigation";
import { Loading } from "@/components";

const Home = () => {
  const router = useRouter();
  useEffect(() => {
    router.replace("/marketplace/atomicals");
  }, []);
  return (
    <div className="py-10 text-center">
      <Loading className="loading-md" />
    </div>
  );
};
export default Home;
